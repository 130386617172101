import React, { useState } from "react";

const Error = () => {
  require("./error.css");

  const [isVisible, setIsVisible] = useState(false);

  if (document.getElementById("msg") != null) setIsVisible(true);

  return (
    <>
      {isVisible && (
        <div className="box">
          <span id="mode"></span>
          <span id="msg"></span>
        </div>
      )}
    </>
  );
};
export default Error;
