import React, { useState, useEffect } from 'react';

const footer = () =>
{
    return(
        <div className="altMetin">
        <a href="https://www.hrpeak.com/" target="_blank" title="HRPeak">Dinamik</a>
        </div>
    )
}
export default footer;