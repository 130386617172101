class URL {

    static getCurrentURL = function (name) {
        var match = document.cookie.match(
          new RegExp("(^| )" + "ReactJS" + "=([^;]+)")
        );
        if (match) {
            return match[2];
        }
        return null;
      }

};
export default URL;