import React, { useState, useEffect } from 'react';
import UserService from "../../services/UserService";

const UserMenu = () => {

    const [user, setUser] = useState(null);

    useEffect(() => {
      getUser();
    },[]);
  
   const getUser = async () =>{
        var res =await UserService.getUserAsync();
        setUser(res.nameSurname);
   }

   return(
    <div className="menuUst">
    <i className="fa fa-home"></i>
    <a href="#">{user}</a>
    <i className="fa fa-user"></i>
    <a href="/uyelik/profil.sec.aspx" style={{ paddingRight: "8px" }}>
      Rol Değiştir
    </a>
    <i className="fa fa-sign-out"></i>
    <a href="#" style={{ paddingRight: "8px" }}>
      Çıkış
    </a>
    <a href="#">
      <i className="fa fa-language"></i>English
    </a>
  </div>

   );
};
export default UserMenu;