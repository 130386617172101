import React, { useState, useEffect } from "react";
import Guarded from "../shared/guarded";
import TestService from "../services/TestService";
import { useTranslation } from 'react-i18next';
import Error from '../components/Notifications/Error/Error';

const User = () => {
  
  const [t, i18n] = useTranslation('common');
  const [data, setData] = useState(null);

  const getdata = async() => {
    return await TestService.getUserAsync();    
  }

  

  useEffect(() => {
    var res = getdata();
    // setData(res);
  },[]);
  

  return (
    <div>
      <Guarded permissions={["RAPOR","aokasdıojasdıo"]}>
        <Error/>
      <h2>Welcome User</h2>
      </Guarded>
      <h1>{t('k.title', {framework:'React'})}</h1>
      <br/>
      <span>
        Welcome the demo of multiple page this page is rendered using second
        layout
      </span>
    </div>
  );
};

export default User;
