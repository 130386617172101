import fetch from "../auth/FetchInterceptor";
import Cookies from "universal-cookie";
const URL = "/api/User";

const UserService = {

   getUserAsync : async () => {
    try {
      const cookies = new Cookies();
      var cookieValue = cookies.get("RU");
      if( cookieValue == undefined || cookieValue == '')
      {
       return await fetch
        .get(`${URL}/GetUserInfo`)
        .then((user:any)=>{     
          if(user != null)
         {
            //TODO: return webform login page
          cookies.set("RU", user);
          return user;
         }
      }
      )
      }else{
        return cookieValue;
      }
    } catch (error) {
      
    }
  }
};
export default UserService;
