import fetch from "../auth/FetchInterceptor";
const URL = "/api/Site";

const SiteService = {

   getSiteAsync : async () => {
    try {
      return await fetch
        .get(`${URL}/GetSiteInfo`)
        .then((res:any)=>{
          return res;
      }
      )
    } catch (error) {
    }
  }
};
export default SiteService;
