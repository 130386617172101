import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import App from "./App";
import UserService from "./services/UserService";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_de from "./translations/de/common.json";
import common_en from "./translations/en/common.json";
import common_tr from "./translations/tr/common.json";
import reportWebVitals from './reportWebVitals';

const getUserLang = async () => {
  var res = await UserService.getUserAsync();
  console.log(res);
};


i18next.init({
  interpolation: { escapeValue: false }, 
  lng: 'en',                              
  resources: {
      en: {
          common: common_en             
      },
      de: {
          common: common_de
      },
      tr: {
        common: common_tr
    },
  },
});
ReactDOM.render(
  <React.StrictMode>
      <I18nextProvider i18n={i18next}>
            <App/>
        </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
