import fetch from "../auth/FetchInterceptor";
const URL = "/api/Test";

const TestService = {

   getUserAsync : async () => {
    try {
       return await fetch
        .get(`${URL}`)
        .then((res:any)=>{
          console.log("Test",res);
         }
      )
     
    } catch (error) {
      console.log("Test_Catch",error);
    }
  }
};
export default TestService;
