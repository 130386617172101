import React, { useState, useEffect } from 'react';

const HeaderMenu = () => {

    return(
        <div className="containerFull menuSayfaContainer">
          <div className="container siteTema">
            <div className="menuSayfa">
              <div>
              <table 
              // cellspacing="0" cellpadding="0" align="center" className="MW"
              ><tbody><tr><td className="PH" style={{whiteSpace: 'nowrap'}}>User Login</td></tr></tbody></table>
              </div>
            </div>
          </div>
        </div>
    )
};
export default HeaderMenu;