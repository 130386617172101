const dev = {
  API_ENDPOINT_URL: "",
  SITE_URL: "https//localhost:3000/",
  WEB_FORM_URL: "http://localhost:51385/"
}
const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
      default:
        return dev;
  }
};

export const env = getEnv();
