import React, { Suspense } from "react";
import { Router, Switch } from "react-router-dom";
import { Route } from "react-router";
import { Redirect } from "react-router-dom";
import { RecoilRoot } from "recoil";
import history from "./helpers/history";
import RoutePaths from "./configs/RoutePathsConfig";
import "./App.css";
/** Layouts **/
import PersonelLayout from "./layouts/personel-layout/PersonelLayout";
import BusinessLayoutRoute from "./layouts/business-layout/BusinessLayout";
/** Components **/
import UserPage from "./views/User";

const routeTree = [
  {
    key: "business",
    path: "/business",
    component: UserPage,
    layout: BusinessLayoutRoute,
  },
  {
    key: "personel",
    path: "/personel",
    component: UserPage,
    layout: PersonelLayout,
  },
];

function App() {
  return (
    <Suspense fallback="loading">
      <div className="App">
        <RecoilRoot>
          <Router history={history}>
            <Switch>
              <Route path="/hasan" render={() => <span>emre</span>} />
              {routeTree.map((item: any) => {
                console.log(item);
                return (
                  <Route
                    path={item.path}
                    render={(props) => (
                      <item.layout {...props}>
                        <item.component {...props} />
                      </item.layout>
                    )}
                  />
                );

                // <Route component={item.layout}>
                //   <Route path={item.path} component={item.component} />
                // </Route>
                // <RouteWrapper
                //   path={item.path}
                //   component={item.component}
                //   layout={item.layout}
                // ></RouteWrapper>
              })}
            </Switch>
          </Router>
        </RecoilRoot>
      </div>
    </Suspense>
  );
}

function RouteWrapper({ component: Component, layout: Layout, ...rest }: any) {
  console.log("c", Component);
  console.log("l", Layout);
  console.log("re", ...rest);
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

export default App;
