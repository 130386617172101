import React, { useState, useEffect } from "react";
import SiteService from "../../services/SiteService";
import UserService from "../../services/UserService";
import GuardedLayout from "../../shared/guardedLayout";
import Language from "../../utils/Language";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

//TODO
import { env } from "../../configs/EnvironmentConfig";
//

interface IProps {
  children: any;
  // any other props that come into the component
}

const BusinessLayout = ({ children }: IProps) => {
  require("./business.layout.css");
  const [user, setUser] = useState({
    nameSurname: "",
    companyName: "",
    multiRole: "",
    role: "",
    roleName: "",
    menu: [],
  });
  const [site, setSite] = useState({ logo: "", language: "" });
  const [activeRightButton, setActiveRightButton] = useState(false);

  useEffect(() => {
    getUser();
    getSite();
  }, []);


  const getUser = async () => {
    var res = await UserService.getUserAsync();
    setUser(res);
  };

  const getSite = async () => {
    var res = await SiteService.getSiteAsync();
    setSite(res);
  };

  return (
    <GuardedLayout roles={"Employer"}>
      <Container fluid>
        <Row>
          <Col xs={12} md={2} lg={2} className="rightLayout">
            <div className="">
              <div className="companyLogo">
                {site?.logo ? (
                  <a href="/">
                    <img src={env.WEB_FORM_URL + site?.logo + ".d"} />
                  </a>
                ) : (
                  <div className="logoYukle">
                    <a
                      className="LB"
                      href={env.WEB_FORM_URL + "/site/logo.aspx"}
                      title="K.LOGO_YUKLE"
                    >
                      <i className="fa fa-camera"></i>K.LOGO_YUKLE
                    </a>
                  </div>
                )}
              </div>
              {site?.logo ? null : (
                <div className="siteDuzenle">
                  <a className="LB" href="/?edit=1">
                    <i className="fa fa-pencil-square-o"></i>Siteyi Özelleştir
                  </a>
                </div>
              )}
              <div className="kullaniciAdSoyad">{user?.nameSurname}</div>
              <div className="firmaAd">{user?.companyName}</div>
            </div>
            <div className="hrCompany"></div>
            <ul>
              {user?.menu
                ? user.menu.map((item: any) => {
                    return (
                      <li id={item.name}>
                        {activeRightButton === true ? (
                          <a
                            href={env.WEB_FORM_URL + item.url}
                            className="active"
                          >
                            <FontAwesomeIcon icon={faHome} />
                            {/* <i className={"fa fa-" + item.icon}></i> */}
                            &nbsp;&nbsp;{item.name}
                          </a>
                        ): (
                          <a href={env.WEB_FORM_URL + item.url}>
                            <FontAwesomeIcon icon={faHome} />
                            &nbsp;&nbsp;{item.name}
                          </a>
                        )}
                      </li>
                    );
                  })
                : null}
            </ul>
          </Col>
          <Col xs={12} md={10} lg={10}>
            <Row>
              <Col
                xs={6}
                md={6}
                lg={6}
                style={{ marginTop: "10px" }}
                className="search"
              >
                <Row>
                  <div
                    className="solMenuIkon"
                    style={{ marginRight: "15px", marginTop: "8px" }}
                  >
                    <i className="fa fa-bars solMenuIkon"></i>
                  </div>
                  <input
                    id="HIZLI_ADAY_ARAMA"
                    type="text"
                    placeholder="Ad-Soyad"
                    className="aramaKutusu"
                  />
                  <button type="button" className="btnAra">
                    <i className="fa fa-search"></i>
                  </button>
                </Row>
              </Col>
              <Col xs={6} md={6} lg={6} className="menuUst">
                <div className="topMenuler">
                  <div
                    className="mobilUser ipucuIcon dropdown menuItem"
                    id="ipucuIkon"
                  >
                    <span className="ipucuIcon">
                      <i className="fa fa-lightbulb-o topMenu"></i>
                    </span>
                    <ul>
                      <li>
                        <i className="fa fa-lightbulb-o"></i>
                        <div>ipucu...</div>
                      </li>
                    </ul>
                  </div>

                  <a id="ayarlar" href="/kurumsal/uyelik.aspx" title="AYARLAR">
                    <i className="fa fa-cog topMenu ayarlar"></i>
                  </a>
                  <a
                    id="bildirim"
                    href="/isveren/bildirim.aspx"
                    title="BILDIRIM"
                  >
                    <i className="fa fa-bell topMenu bildirimIkon"></i>
                  </a>
                  {!site?.language ? (
                    <a
                      className="dil"
                      href={
                        env.WEB_FORM_URL +
                        "uyelik/change.language.aspx?source=" +
                        window.location.href
                      }
                    >
                      {/* {Language.getLanguage()} */}
                    </a>
                  ) : null}
                  <div className="mobilUser dropdown">
                    <a href={env.WEB_FORM_URL + "uyelik/cikis.aspx"}>
                      <span className="user">
                        {/* TODO: İMG yoksa ad soyad ilk harf varsa img */}
                        <span className="ilk">AK</span>
                        <span className="topBilgi">
                          {user?.nameSurname}
                          <small>{user?.roleName}</small>
                        </span>
                      </span>
                    </a>
                    {user?.multiRole ? (
                      <a
                        href={
                          env.WEB_FORM_URL +
                          "uyelik/profil.sec.aspx?source=" +
                          window.location
                        }
                      >
                        Rol değiştir
                      </a>
                    ) : null}
                    <ul>
                      <li>
                        <a
                          href="/kurumsal/uyelik.aspx"
                          style={{ paddingRight: "8px" }}
                        >
                          <i className="fa fa-home"></i> K.HESABIM
                        </a>
                      </li>
                      <li id="siteHaritasi">
                        <a href="/kurumsal/harita.aspx">
                          <i className="fa fa-sitemap"></i>SITE_HARITASI
                        </a>
                      </li>
                      <li className="kariyerSite">
                        <a href="/">
                          <i className="fa fa-laptop"></i>K.KARIYER_SITE
                        </a>
                      </li>
                      <li>
                        <a
                          href="/uyelik/profil.sec.aspx"
                          style={{ paddingRight: "8px" }}
                        >
                          <i className="fa fa-user"></i>K.ROL_DEGISTIR
                        </a>
                      </li>
                      <li>
                        <a href="/uyelik/cikis.aspx">
                          <i className="fa fa-sign-out"></i>K.CIKIS
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </GuardedLayout>
  );
};
export default BusinessLayout;
