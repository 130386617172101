import React, { useState, useEffect } from 'react';
import Logo from "./Logo";

const TopMenu = () => {
    
    return(
        <>
        <Logo/>
      <div className="menu">
        <div className="menuClose">×</div>
        <ul className="menu">
          <li className="ripple active">
            <a href="#"><i className="fa fa-home"></i><span>Dinamik</span></a>
          </li>
        </ul>
      </div>
   </>
    )
}
export default TopMenu;