import React, { useState, useEffect } from 'react';
import TopMenu from "../../components/layout-components/TopMenu";
import UserMenu from "../../components/layout-components/UserMenu";
import HeaderMenu from "../../components/layout-components/HeaderMenu";
import Footer from "../../components/layout-components/Footer";

interface IProps {
  children: any;
  // any other props that come into the component
}


const PersonelLayout = ({ children }: IProps) => {

  require('../../assets/layout-shared/web/default.css');

  return(
    <>
    <div className="overlay" id="overlay"></div>
    <div className="page">
      <div className="containerFull">
        <div className="container header">
          <UserMenu/>
          <TopMenu/>
        </div>
        <HeaderMenu/>
        <div className="container">
                <div className="content">{children}</div>
                <Footer/>
             </div>
      </div>
    </div>
  </>
  );
};

export default PersonelLayout;
