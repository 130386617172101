import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL, SITE_URL } from "../configs/AppConfig";
import history from "../helpers/history";
import URL from "../utils/URL";
//TODO
import { env } from "../configs/EnvironmentConfig";
//

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

service.interceptors.request.use(
  (config) => {
    const headerKeys = URL.getCurrentURL();
    if (headerKeys) config.headers["Authorization"] = headerKeys;
    // else
    //   history.push(
    //     env.WEB_FORM_URL + "uyelik/login.aspx?source=" + window.location
    //   );

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);



service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {    
    var m1 = document.getElementById("mode");
    var m2 = document.getElementById("msg");
    if(m1!=null)m1.innerText= "error";
    if(m2!=null)m2.innerText= error.response.data;

    return Promise.reject(error);
  }
);

export default service;
