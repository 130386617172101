

const logo = () => {
   return(
    <div className="logo">
    <a href="/">
      <img src="https://pbs.twimg.com/profile_images/1125325576313745408/BpU23l4R_400x400.png" />
    </a>
  </div>
   )
}
export default logo;